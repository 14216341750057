<template>
  <div>
    <ui-component-modal
      :modalTitle="$t('Components.Events.EventUrl.Edit.Main_Title')"
      :onClickCancel="onClickCancel"
      :onClickSave="saveEvent"
      :isSaving="isSaving"
      :isSavingSuccess="isSavingSuccess"
      :isSavingError="isSavingError"
      :hideFooterButtons="isSavingError || isSavingSuccess"
      :showModal="showModal"
      @closeModal="onClickCancel"
    >
      <template v-slot:content>
        <message
          :message="`${$t('Components.Events.EventUrl.Edit.Error_AddLink')}`"
        />
        <div class="has-margin-bottom">
          <ui-base-input-field
            v-model="urlInput"
            :error="urlErrors"
            :placeholder="'http://www.example.com'"
            :label="'URL:'"
            fieldType="text"
            @input="$v.urlInput.$touch()"
            @blur="$v.urlInput.$touch()"
          />
        </div>
        <ui-checkbox
          :label="$t(`Components.Events.EventUrl.Edit.Label_LinkTicketSell`)"
          v-model="ticketSaleCheck"
        />
      </template>
    </ui-component-modal>
  </div>
</template>

<script>
import Message from '@/components/UI/Message'
import { mapMutations, mapState } from 'vuex'
import eventProvider from '@/providers/event'

import { url } from 'vuelidate/lib/validators'
const touchMap = new WeakMap()

export default {
  components: {
    message: Message,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      urlInput: '',
      ticketSaleCheck: false,
    }
  },

  /**
   * Form validation rules
   */
  validations: {
    urlInput: {
      url,
    },
  },

  computed: {
    ...mapState('eventStore', ['eventData']),

    urlErrors() {
      const errors = []
      if (!this.$v.urlInput.$dirty) return errors
      !this.$v.urlInput.url && errors.push('Not a valid url')
      return errors
    },
  },

  created() {
    this.mEvent = JSON.parse(JSON.stringify(this.eventData))
    this.urlInput = this.mEvent.Url
    this.ticketSaleCheck = this.mEvent.IsTicketLink
  },

  methods: {
    ...mapMutations('eventStore', ['setEventData']),

    saveEvent() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true
        this.$v.$touch()
        if (this.$v.$invalid) {
          // 'ERROR'
          self.isSaving = false
        } else {
          self.mEvent.Url = self.urlInput
          self.mEvent.IsTicketLink = self.ticketSaleCheck
          eventProvider.methods
            .updateEvent(self.mEvent)
            .then((response) => {
              if (response.status === 200) {
                self.isSavingSuccess = true
                self.setEventData(response.data)

                let t = setTimeout(() => {
                  self.onClickCancel()
                  clearTimeout(t)
                }, 1500)
              }
            })
            .catch((e) => {
              ////console.log(error.response)
              self.isSavingError = true
            })
            .finally(() => {
              self.isSaving = false
            })
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/template';
.display {
  display: flex !important;
}
.form-url {
  display: flex;
  position: relative;
  .select {
    position: relative;
    background-color: transparent;
    margin-right: 10px;
    &:focus {
      outline: none;
    }
  }
  .text-url {
    width: 50%;
    padding-left: 5px;
    border-radius: 6px;
    border: 1px solid $grey-lighter;
    &:focus {
      outline: none;
    }
  }
}
</style>
